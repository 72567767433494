import React from 'react'
import { graphql } from 'gatsby'

import BlogLayout from 'layouts/Blog'
import SEO from 'components/shared/SEO'
import { ContentSection } from 'components/Blog/Post'

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const { siteTitle, blogUrl } = this.props.data.site.siteMetadata
    const { previous, next } = this.props.pageContext

    return <ContentSection post={post} previous={previous} next={next} />
  }
}

export default ({ location, data, pageContext }) => (
  <BlogLayout location={location} title={data.mdx.frontmatter.title}>
    <SEO
      title={data.mdx.frontmatter.title}
      image={
        data.site.siteMetadata.blogUrl +
        data.mdx.frontmatter.featuredImage.childImageSharp.sizes.src
      }
      description={data.mdx.frontmatter.description || data.mdx.excerpt}
      pathname={location.pathname}
    />
    <BlogPostTemplate data={data} pageContext={pageContext} />
  </BlogLayout>
)

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        blogUrl
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      fields {
        slug
      }
      code {
        body
      }
      frontmatter {
        title
        tags
        featuredImage {
          childImageSharp {
            sizes(maxWidth: 630) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
      }
    }
  }
`
